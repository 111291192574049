import { useEffect } from 'react';

export default function App() {
  useEffect(() => {
    window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
  }, []);
  return (
    <div>
      <h1 style={{fontSize: 300, textAlign: "center"}}>Hello There</h1>
    </div>
)
}